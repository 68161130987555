import React from 'react';
import styled from "styled-components";

import '../components/layout.css'
import PlusComponent from "./plusComponent";

const Container = styled.div`
  color: #FFFFFF;
  margin-left: 22px;
  margin-top: 15px;
  @media (max-width: 968px) {
    margin-left: 15px;
    margin-top: 15px;
  }
`;


const LineText = styled.div`
  font-size: 18px;
  font-weight: 300;
  line-height: 27px;
  margin-bottom: 30px;
`

const ListBlock = styled.ul`
  padding: 0;

  & li {
    margin-left: 20px;
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 27px;
    font-weight: 300;
  }
`

const Content = styled.div`
  @media (max-width: 968px) {
    margin-left: 10px;
  }
`

const TYPES = {
    "text": (item) => <LineText key={item.text}>{item.text}</LineText>,
    "list": (item) => <ListBlock key={item + "a"}><span
        style={{fontSize: "16px"}}>{item.title}</span>{item.items.map(item => <li key={item}>{item}</li>)}</ListBlock>
};

const InformationBlockList = ({data}) => {
    return (
        <>
            <PlusComponent text='Что такое умный дом'/>
            <Container>
                <Content>
                    {
                        data.map(item => TYPES[item.type](item))
                    }
                </Content>

            </Container>
        </>
    );
};

export default InformationBlockList;
