import React, {useState} from 'react';
import {ReactSVG} from 'react-svg'
import {graphql} from "gatsby";
import Header from "../components/Header/header";
import CarouselProject from "../components/Carousel/CarouselProjects"
import InformationBlock from "../ui/informationBlock";
import InformationBlockList from "../ui/InformationBlockList";
import RunTextComponent from "../components/RunText";
import {ContainerCarousel, ContainerL} from "../ui/containers";
import Footer from "../components/Footer/footer";
import SliderCard from "../components/Slider/SliderCard"
import SliderIncredible from "../components/Slider/SliderIncredible"
import Partners from "../components/Partners/index"
import FormBlock from '../components/Form';
import Control from "../components/Control";
import styled from "styled-components";
import Modal from "../ui/modal";
import Seo from "../components/seo";
import "../components/layout.css";


const infoData = [
    {
        type: "text",
        text: `Умный дом – это управляемая продвинутой программой система, позволяющая сделать вашу жизнь намного приятней, проще, безопасней. 
        Множество мелких действий вроде открытия и закрытия штор, регулировки освещения и отопления могут показаться несложными, но они отбирают самый ценный ресурс современного человека – его время. Под управлением искусственного интеллекта ваше жилище само включит уличное освещение, когда стемнеет, перекроет воду в случае внезапной протечки, проконтролирует состав воздуха и, если нужно, включит вентиляцию.`
    },
    {
        type: "list",
        title: "Что Вы получаете:",
        items: [
            "Безопасность. Информацию с установленных датчиков и видеокамер умный дом отправляет на облачный сервер, доступный для смартфона, планшета, ноутбука. Это позволяет вам контролировать происходящее в доме даже в ваше отсутствие. Больше никаких жутких историй о том, как ребенок открыл дверь незнакомцам!",
            "Удобство. Роллеты, жалюзи или шторы закроют окна, чтобы утреннее солнце не разбудило вас раньше запланированного времени. Больше не придётся спотыкаться в коридоре или на тёмной лестнице. Отопление включится самостоятельно, как только датчик покажет, что температура в помещении опустилась ниже заданной отметки",
            "Возможность подбора систем от эконом-варианта до премиум-класса. Умный дом состоит из  взаимосвязанных систем, из которых можно выбрать только те, которые наиболее актуальны для Вас в данный момент.  Вы всегда сможете приобрести новые модули и установить дополнительное оборудование",
            "Максимальная индивмдуализация. Каждый проект разрабатывается с учётом пожеланий заказчика и его привычного ритма жизни. Жилище подстраивается под хозяина, экономя его время, создавая все условия для качественной жизни, работы, отдыха",
            "Мультимедиа. Если Вы заядлый киноман или не представляете свою жизнь без любимой музыки, возможность за несколько мгновений включить полюбившийся трек или насладиться качественным изображением определенно придётся вам по вкусу."
        ]
    },
    {
        type: "text",
        text: <div>Выбирайте лучшее – выбирайте intel building <br></br>
            Умный дом – это надёжная защита. А когда речь заходит о безопасности вас и вашей семьи, лучше всего поручить
            работу мастерам своего дела. Наши сотрудники выполнят все необходимые замеры и разработают проект с учётом
            всех ваших пожеланий. Монтаж производится бригадой специалистов. С нами мечта о доме, который надёжнее
            крепости, станет прекрасной реальностью.
        </div>
    }
]


const Svg = styled(ReactSVG)`
  & > div > svg > path {
    fill: #333131
  }
`;


const Index = ({data}) => {

    const [openModal, setOpenModal] = useState(false);
    const projectsData = data.allStrapiProject.nodes;
    const servicesData = data.allStrapiService.nodes;
    const businessData = data.allStrapiBusiness.nodes[0].blocks;

    const services = servicesData.map(item => {
        return {
            title: item.title,
            img: <Svg src={item.iconSvg.localFile.publicURL}/>,
            link: 'services/' + item.id,
        }
    })

    const carouselData = projectsData.map(item => {
        return {
            title: item.title,
            description: item.description.substr(0, 60) + "...",
            img: item.carousel[0].localFile.publicURL,
            url: item.id
        }
    })
    const business = businessData.map(item => {
        return {
            img: item.image.localFile.publicURL,
            title: item.title,
            description: item.description.substr(0, 120) + "...",
            link: `/business#${item.id}`
        }
    })


    const toggleOpenModal = () => {
        if (!openModal) document.body.style.overflow = "hidden";
        else document.body.style.overflow = "auto";
        setOpenModal(!openModal);
    }
    return (
        <>
            <Seo title={"Главная"}/>

            <Header/>
            {openModal ? (
                <Modal toggleOpenModal={toggleOpenModal}/>
            ) : (
                <>
                    <ContainerG>
                        <RunTextComponent toggleOpenModal={toggleOpenModal}/>

                        <InformationBlock
                            title={<div>INTEL BUILDING</div>}
                            text={
                                "Мы – профессиональные инсталляторы систем «Умный дом». Спроектируем, смонтируем, и настроим дом Вашей мечты. Все системы будут работать именно так, как Вы захотите, а с управлением справится даже ребенок."
                            }
                            buttonText={"ПОДРОБНЕЕ О НАС"}
                        />
                    </ContainerG>

                    <ContainerR style={{margin: "200px auto"}}>
                        <SliderCard
                            black={true}
                            data={services}
                            title="УСЛУГИ"
                            toggleOpenModal={toggleOpenModal}
                        />
                    </ContainerR>

                    <ContainerR style={{margin: "200px auto"}}>
                        <SliderIncredible data={business}/>
                    </ContainerR>

                    <ContainerCarousel id="project">
                        <CarouselProject items={carouselData} maxWidth={2000}/>
                    </ContainerCarousel>

                    <ContainerL style={{marginBottom: "202px"}}>
                        <Partners/>
                    </ContainerL>
                    <ContainerL>
                        <Control/>
                    </ContainerL>

                    <ContainerL style={{margin: "160px auto"}}>
                        <InformationBlockList data={infoData}/>
                    </ContainerL>

                    <ContainerL style={{margin: "150px auto"}}>
                        <FormBlock/>
                    </ContainerL>

                    <Footer toggleOpenModal={toggleOpenModal}/>
                </>
            )}
        </>
    );
}

export const query = graphql`
    query IndexQuery {
        allStrapiProject {
            nodes {
                title
                description
                id
                carousel {
                    localFile {
                        publicURL
                    }
                }
            }
        }
        allStrapiBusiness {
            nodes {
                blocks {
                    id
                    title
                    description
                    image {
                        localFile {
                            publicURL
                        }
                    }
                }
            }
        }
        allStrapiService {
            nodes {
                id
                iconSvg {
                    localFile {
                        publicURL
                    }
                }
                title
            }
        }
    }
`

const ContainerG = styled(ContainerL)`
  margin: 100px auto;
  @media (max-width: 1268px) {
    margin: 20px auto;
    padding: 10px;
  }
`
const ContainerR = styled.div`
  padding-left: 150px;
  max-width: 1600px;
  width: 100%;
  @media (max-width: 1268px) {
    padding: 10px;
  }
`
export default Index;


