import React from 'react';
import styled from "styled-components";
import {Link} from "gatsby";

import ArrowRightSmall from "../images/ArrowRightSmall.svg";
const ImageCard = ({small, item}) => {
    return (
      <>
        {small ? (
          <SmallContainer>
            <SmallPicture src={item.img}>
              <SmallGradient>
                <BlackBlock />
              </SmallGradient>
            </SmallPicture>
            <Information>
              <SmallTitle>{item.title}</SmallTitle>
              <SmallAdditional>
                <InfoCardLink to={item.link}>
                  <Span>ПОДРОБНЕЕ</Span>
                  <Arrow>
                    <img
                      src={ArrowRightSmall}
                      style={{ marginLeft: "14px" }}
                      alt="More"
                    />
                  </Arrow>
                </InfoCardLink>
              </SmallAdditional>
            </Information>
          </SmallContainer>
        ) : (
          <PicContainer src={item.img}>
            <Gradient>
              <Title>{item.title}</Title>
              <Description>{item.description}</Description>
              <Additional>
                <InfoCardLink to={item.link}>
                  <Span>ПОДРОБНЕЕ</Span>
                  <Arrow>
                    <img
                      src={ArrowRightSmall}
                      style={{ marginLeft: "14px" }}
                      alt="More"
                    />
                  </Arrow>
                </InfoCardLink>
              </Additional>
            </Gradient>
          </PicContainer>
        )}
      </>
    );
};

export default ImageCard;

const Arrow = styled.div`
  transition-duration: 0.3s;
  &:hover {
    margin-left: 10px;
  }
`;

const SmallContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 40px;
`

const Information = styled.div`
  width: 400px;
  padding-left: 24px;
`

const SmallPicture = styled.div`
  width: 400px;
  height: 191px;
  background-size: cover;
  background-repeat:no-repeat;
  background-image: url(${props => props.src || ""});
  border-radius: 16px 16px 0px 0px;
`

const BlackBlock = styled.div`
  position: relative;
  width: 316px;
  height: 39px;
  background: #0B0A0A;
  top: 152px;
`

const SmallGradient = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(360deg, rgba(0, 0, 0, 0.78) 0%, rgba(11, 10, 10, 0.37) 100%);
  border-radius: 16px 16px 0px 0px;
`

const PicContainer = styled.div`
  transition:all 0.5s ease-out;
  z-index: 20;
  width: 400px;
  height: 562px;
  background-image: url(${props => props.src || ""});
  border: 1px solid #000000;
  border-radius: 16px;
  margin-right: 40px;
  @media (max-width: 600px) {
    width: 253px;
    height: 354px;
    margin-right:40px;
  }
`
const Gradient = styled.div`
  border: 1px solid #000000;
  border-radius: 16px;
  background: linear-gradient(0.29deg, #000000 0.16%, #0B0A0A 0.17%, rgba(11, 10, 10, 0.93) 39.34%, rgba(11, 10, 10, 0.41) 99.66%);
  width: 400px;
    left: 0;
  height: 562px;
  display: flex;
  padding: 24px;
  flex-direction: column;
`

const Title = styled.div`
  margin-top: 256px;
  color: #FFFFFF;
  font-size: 22px;
  @media (max-width: 600px) {
    margin-top: 95px;
    font-size: 15px;
  }
`

const SmallTitle = styled.div`
  margin-top: 53px;
  color: #FFFFFF;
  font-size: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const Description = styled.div`
  padding-right: 65px;
  margin-top: 26px;
  color: #ffffff;
  font-size: 15px;

  overflow-y: auto;
  @media (max-width: 600px) {
    font-size: 13px;
    max-width: 73%;
  }
`;

const Additional = styled.div`
  margin-top: 42px;
  color: #FFFFFF;
  font-size: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none!important;
`

const SmallAdditional = styled(Additional)`
  margin-top: 23px;
  

`
export const TextButton = styled.div`
  min-width: 210px;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 16px;
  @media (max-width: 600px) {
    font-size: 13px;
    margin-left: 15px;
  }
`;
const InfoCardLink = styled(Link)`
  display: flex;
  width: fit-content;
  color: white;
  border-color: #1C1A1A;
  text-decoration: none!important;
  transition: 0.1s border-color;
  &:hover {
    cursor: pointer;
    //border-bottom: 2px solid #4285F4;
    border-radius: 2px;
    text-decoration: none!important;
  }
`;

const Span = styled.span`
  transition-duration: .3s;
  &:hover {
    margin-right: 10px;
    
  }
`;
