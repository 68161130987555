import React, { useState } from "react";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import ImageCard from "../../ui/imageCard";
import { SliderFlow } from "./SliderFlow";
import { Carousel } from "antd";
import styled from "styled-components";
import PlusComponent from "../../ui/plusComponent";

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToScroll: 1,
  draggable: true
};


const IncredibleSlider = ({ data }) => {
  const [value, setValue] = useState(0);
  const screens = useBreakpoint();
  const handleChange = (e) => {
    setValue(Math.ceil(e));
  };

  return (
    <>
      <Container>
        <PlusComponent text="для кого" />
        {screens.md ? (
          <CarouselContainer>
            <ImageCard item={data[value]} />
            <Container>
              <div
                style={{

                  display: "flex",
                  marginLeft: -440 * value,
                  transition:'all 1s ease-out'
                }}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {data.map((item, index) =>
                    index !== 0 ? (
                      <div key={index}>
                        <ImageCard small item={item} />
                      </div>
                    ) : null
                  )}
                </div>
              </div>

              <div style={{ margin: "35px 0 0 40px" }}>
                <SliderFlow
                  width={
                    screens.xxl
                      ? "840px"
                      : screens.xl
                      ? "600px"
                      : screens.lg
                      ? "450px"
                      : "200px"
                  }
                  ChangeCard={handleChange}
                  count={data.length}
                />
              </div>
            </Container>
          </CarouselContainer>
        ) : (
          <div style={{ marginTop: 45 }}>
            <Carousel {...settings} slidesToShow={1.2} >
              {data.map((item, index) => (
                <div key={index}>
                  <div key={index}>
                    <ImageCard item={item} />
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        )}
      </Container>
    </>
  );
};

export default IncredibleSlider;


const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const CarouselContainer = styled.div`
  margin-top: 87px;
  margin-left: 19px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
`;

export const BtnContainer = styled.div`
  margin-top: 35px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

