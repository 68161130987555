import React from "react";
import styled from "styled-components";
import '../layout.css'
import IntelBuilding from '../../images/big-logo-ib.jpg'
import {Button} from "antd";
import Arrow from "../../assets/arrowBack.svg";

const DivOne = styled.div`
  color: white;
  position: relative;
  font-weight: bold;
  font-size: 72px;
  line-height: 96px;
  text-transform: uppercase;
  @media (max-width: 1300px) {
    font-size: 62px;
    line-height: 86px;
  }
  @media (max-width: 800px) {
    font-size: 48px;
    line-height: 58px;
  }
`;

const SpanOne = styled.span`
  color: white;
  font-size: 72px;
  line-height: 96px;
  font-weight: 300;
  text-transform: uppercase;
  @media (max-width: 1300px) {
    font-size: 52px;
    line-height: 86px;
  }
  @media (max-width: 968px) {
    font-size: 38px;
    line-height: 46px;
  }
  @media (max-width: 800px) {
    font-size: 33px;
    line-height: 58px;
  }
`;

const BorderTop = styled.div`
  width: 111px;
  border-top: 2px solid #4285F4;
`;

const DivTwo = styled.div`
  font-size: 18px;
  line-height: 22px;
  color: white;
  width: 200px;
  @media (max-width: 968px) {
    font-size: 15px;
  }
`;

const Btn = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 62px;
  height: 62px;
  margin-right: 18px;
  transition-duration: .2s;
  border: none;
  :hover {
    background-color: ${props => props.white ? '#4285F4' : '#ffffffff'};
    & > svg > path {
      fill: ${props => props.white ? '#ffffffff' : '#4285F4'};
    }
  }
  & > svg > path {
    fill: ${props => props.white ? '#4285F4' : '#ffffffff'};
  }
  @media (max-width: 800px) {
    width: 48px;
    height: 48px;
  }
`;

const Container = styled.div`
  display: flex;
  margin-bottom: 300px;
  justify-content: space-between;
  @media (max-width: 967px) {
    margin-bottom: 150px;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
  }
`;


const Prof = styled.div`
  margin-left: 27px;
  @media (max-width: 800px) {
    margin-top: 31px;
  }
`;

const Block = styled.div`
  @media (max-width: 800px) {
    margin-bottom: 34px;
  }
`;

const BtnContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 200px;
  transition-duration: 0.2s;
  color: #4285f4;
  font-weight: bold;
  font-size: 16px;
  line-height: 46px;
  z-index: 5;
  text-transform: uppercase;
  transition-duration: 0.2s;
  &:hover {
    color: white;
  }
  @media (max-width: 968px) {
    margin-top: 100px;
  }
  @media (max-width: 800px) {
    margin-top: 46px;
    font-size: 12px;
    line-height: 46px;
  }
`;

const SpanCintainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 800px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;



const A = styled.a`
  transition-duration: 0.2s;
  color:inherit;
  &:hover {
    color: white;
  }
`;

const ImageBackground = styled.div`
  background: url(${IntelBuilding});
  cursor: default;
  background-repeat: no-repeat;
  background-position-x:36px;
  background-position-y:20px;
  background-size:92% 90%;
  width: fit-content;
  background-clip: text;
  -webkit-background-clip: text;
  & span{
    font-family: sans-serif;
    font-weight: 900;
    font-size: 572px;
    line-height: 400px; 
    color: rgba(255,255,255,0);
    white-space: nowrap;
  }
  @media (max-width: 1180px) {
    background-size:88% 70%;
    & span{
      font-size: 35vw;
      line-height: 26.5vw; 
    }
  }
  @media (max-width: 1080px) {
    background-size:88% 60%;
    & span{
      font-size: 35vw;
      line-height: 26.5vw; 
    }
  }
  @media (max-width: 968px) {
    background-size:88% 81%;
    & span{
      font-size: 35vw;
      line-height: 26.5vw; 
    }
  }
  @media (max-width: 768px) {
    & span{
      font-size: 90vw;
      line-height: 63vw; 
    }
  }
`

const RunTextComponent = (props) => {

    return (
        <Container>
            <Block>
                <DivOne>умный дом</DivOne>
                <SpanCintainer style={{}}>
                    <SpanOne>
                        в москве
                    </SpanOne>

                    <Prof>
                        <BorderTop />
                        <DivTwo>Профессиональные инсталляторы систем</DivTwo>
                    </Prof>
                </SpanCintainer>
                <BtnContainer>
                    <Btn
                        type="primary"
                        shape="circle"
                        size={"large"}
                        onClick={props.toggleOpenModal}
                    >
                        <Arrow />
                    </Btn>
                    <A onClick={props.toggleOpenModal}>Рассчитать стоимость</A>
                </BtnContainer>
            </Block>
            <ImageBackground>
                <span>IB</span>
            </ImageBackground>
        </Container>
    );
}

export default RunTextComponent;
